import React from 'react';
import { motion } from 'framer-motion';
// import bg from '../../assets/bg.png'
import HowItWorks from './HowItWorks';
import Hero from './Hero';
import WhyToChooseUs from './WhyToChooseUs';
import About from '../About/About';

export default function Home() {
  return (
    <motion.div
      className="flex flex-col items-center text-justify justify-between w-full lg:min-h-screen bg-gray-100 p-6"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Hero />

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient my-4" />
      <About />

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient my-4" />
      <HowItWorks />

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient my-4" />

      <WhyToChooseUs />

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient my-4" />


    </motion.div>
  );
}

