import React from 'react';
import { motion } from 'framer-motion';
import bg from '../../assets/bg.png'

const Hero = () => {
  return (
    <div className='flex flex-col lg:flex-row min-h-fit md:min-h-screen items-center'>

      <motion.div
        className="w-full lg:w-1/2 p-0 md:p-8 space-y-6 "
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
      >
        <h1 className="lg:text-5xl text-2xl font-bold text-gray-800 lg:text-justify">
          <span className='bg-custom-gradient text-transparent bg-clip-text'>TheScrapWala is An Online Scrap Collection in Betul </span>
          {/* <div className='mt-8'> */}
          Get cash for your recyclables from the comfort of your home with us.
          {/* <span className="bg-custom-gradient text-transparent bg-clip-text">
              TheScrapWala
            </span> */}
          {/* </div> */}
        </h1>
        <div className='font-semibold'>
          We buy recyclable scrap such as old iron scrap, old plastic scrap, old paper scrap, old books scrap, old copies scrap, and old e-waste scrap.
        </div>

      </motion.div>

      <motion.div
        className="w-full lg:w-1/2 p-2 md:p-8"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
      >
        <p className="text-lg text-gray-600 text-justify">
          <img
            src={bg}
            style={{ filter: 'drop-shadow(3px -3px 2px #ababab) hue-rotate(-59deg)' }}
            className=""
          />
        </p>

      </motion.div>

    </div >

  );
}

export default Hero;
