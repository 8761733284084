import React from 'react';
import { motion } from 'framer-motion';
import EcoFriendly from '../../assets/EcoFreindly.png';
import Convenience from '../../assets/Convenience.png';
import Trust from '../../assets/Trust.png';
import AccurateWeight from '../../assets/AccurateWeight.png';
import '../../App.css'; // Make sure your custom CSS file is imported
import BestPrices from '../../assets/BestPrices.png';

const WhyToChooseUs = () => {
  return (
    <div className='flex flex-col w-full p-4'>
      {/* Title Section */}
      <motion.div
        className="flex flex-col items-center text-justify justify-between w-full bg-gray-100 pb-6 relative"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl md:text-3xl font-bold p-2 uppercase text-gray-800">Why To Choose Us</h2>
      </motion.div>

      <motion.div className='flex justify-around gap-4 flex-wrap'>

        {/* Feature 1: Best Prices */}
        <div className='group rounded-xl hover:rounded-none relative w-full md:w-1/2 lg:w-1/4 flex flex-col border border-gray-100 bg-gray-200 p-4 md:p-8 justify-center items-center overflow-hidden text-black'>
          <span className="ease absolute border-2 left-0 top-0 h-0 w-0 border-t-2 border-[#007A8E] transition-all duration-300 group-hover:w-full"></span>
          <span className="ease absolute border-2 bottom-0 left-0 h-0 w-0 border-l-2 border-[#007ABE] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 right-0 top-0 h-0 w-0 border-r-2 border-[#007A8E] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 bottom-0 right-0 h-0 w-0 border-b-2 border-[#007ABE] transition-all duration-300 group-hover:w-full"></span>
          <div className="flex flex-row gap-2 items-center justify-center">
            <img src={BestPrices} className='h-12 md:h-auto md:w-24 object-cover' alt="Best Prices" />
            <div>
              <p className='mt-2 text-sm md:text-xl text-center font-bold'>Best Rates</p>
              <p className='text-xs md:text-sm text-center'>Get the highest returns for your recyclable materials with our network of recyclers.</p>
            </div>
          </div>
        </div>

        {/* Feature 2: Convenience */}
        <div className='group rounded-xl hover:rounded-none relative w-full md:w-1/2 lg:w-1/4 flex flex-col border border-gray-100 bg-gray-200 p-4 md:p-8 justify-center items-center overflow-hidden text-black'>
          <span className="ease absolute border-2 left-0 top-0 h-0 w-0 border-t-2 border-[#007A8E] transition-all duration-300 group-hover:w-full"></span>
          <span className="ease absolute border-2 bottom-0 left-0 h-0 w-0 border-l-2 border-[#007ABE] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 right-0 top-0 h-0 w-0 border-r-2 border-[#007A8E] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 bottom-0 right-0 h-0 w-0 border-b-2 border-[#007ABE] transition-all duration-300 group-hover:w-full"></span>
          <div className="flex flex-row gap-2 items-center justify-center">
            <img
              style={{ filter: "hue-rotate(245deg)" }}
              src={Convenience} className='h-12 md:h-auto md:w-24 object-cover' alt="Convenience" />
            <div>
              <p className='mt-2 text-sm md:text-xl text-center font-bold'>Ultimate Convenience</p>
              <p className='text-xs md:text-sm text-center'>We offer hassle-free doorstep pickups that suit your schedule, making recycling effortless.</p>
            </div>
          </div>
        </div>

        {/* Feature 3: Trust */}
        <div className='group rounded-xl hover:rounded-none relative w-full md:w-1/2 lg:w-1/4 flex flex-col border border-gray-100 bg-gray-200 p-4 md:p-8 justify-center items-center overflow-hidden text-black'>
          <span className="ease absolute border-2 left-0 top-0 h-0 w-0 border-t-2 border-[#007A8E] transition-all duration-300 group-hover:w-full"></span>
          <span className="ease absolute border-2 bottom-0 left-0 h-0 w-0 border-l-2 border-[#007ABE] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 right-0 top-0 h-0 w-0 border-r-2 border-[#007A8E] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 bottom-0 right-0 h-0 w-0 border-b-2 border-[#007ABE] transition-all duration-300 group-hover:w-full"></span>
          <div className="flex flex-row gap-2 items-center justify-center">
            <img
              style={{ filter: "hue-rotate(200deg)" }}
              src={Trust} className='h-12 md:h-auto md:w-24 object-cover' alt="Trust" />
            <div>
              <p className='mt-2 text-sm md:text-xl text-center font-bold'>Reliable & Transparent</p>
              <p className='text-xs md:text-sm text-center'>Our trained and verified staff use precise weighing methods to ensure accurate payments every time.</p>
            </div>
          </div>
        </div>

        {/* Feature 4: Eco-Friendly */}
        <div className='group rounded-xl hover:rounded-none relative w-full md:w-1/2 lg:w-1/4 flex flex-col border border-gray-100 bg-gray-200 p-4 md:p-8 justify-center items-center overflow-hidden text-black'>
          <span className="ease absolute border-2 left-0 top-0 h-0 w-0 border-t-2 border-[#007A8E] transition-all duration-300 group-hover:w-full"></span>
          <span className="ease absolute border-2 bottom-0 left-0 h-0 w-0 border-l-2 border-[#007ABE] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 right-0 top-0 h-0 w-0 border-r-2 border-[#007A8E] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 bottom-0 right-0 h-0 w-0 border-b-2 border-[#007ABE] transition-all duration-300 group-hover:w-full"></span>
          <div className="flex flex-row gap-2 items-center justify-center">
            <img src={EcoFriendly} className='h-12 md:h-auto md:w-24 object-cover' alt="Eco-Friendly" />
            <div>
              <p className='mt-2 text-sm md:text-xl text-center font-bold'>Eco-Conscious</p>
              <p className='text-xs md:text-sm text-center'>We prioritize sustainable practices, ensuring your recyclables are handled responsibly.</p>
            </div>
          </div>
        </div>

        {/* Feature 5: Accurate Weight */}
        <div className='group rounded-xl hover:rounded-none relative w-full md:w-1/2 lg:w-1/4 flex flex-col border border-gray-100 bg-gray-200 p-4 md:p-8 justify-center items-center overflow-hidden text-black'>
          <span className="ease absolute border-2 left-0 top-0 h-0 w-0 border-t-2 border-[#007A8E] transition-all duration-300 group-hover:w-full"></span>
          <span className="ease absolute border-2 bottom-0 left-0 h-0 w-0 border-l-2 border-[#007ABE] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 right-0 top-0 h-0 w-0 border-r-2 border-[#007A8E] transition-all duration-300 group-hover:h-full"></span>
          <span className="ease absolute border-2 bottom-0 right-0 h-0 w-0 border-b-2 border-[#007ABE] transition-all duration-300 group-hover:w-full"></span>
          <div className="flex flex-row gap-2 items-center justify-center">
            <img src={AccurateWeight}
              style={{ filter: "hue-rotate(106deg)" }}
              className='h-12 md:h-auto md:w-24 object-cover' alt="Accurate Weight" />
            <div>
              <p className='mt-2 text-sm md:text-xl text-center font-bold'>Precise Weighing</p>
              <p className='text-xs md:text-sm text-center'>We use certified digital weighing scales, ensuring you receive exact payouts for your recyclables.</p>
            </div>
          </div>
        </div>

      </motion.div>
    </div>
  );
};

export default WhyToChooseUs;
