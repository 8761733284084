import React from 'react';
import RateBox from './RateBox';
import OfficePapersImg from '../../assets/OfficePapers.png';
import SoftPlasticImg from '../../assets/SoftPlastic.png';
import PlasticItemsImg from '../../assets/PlasticItems.png';
import NewsPapersImg from '../../assets/NewsPapers.png';
import BooksAndCopiesImg from '../../assets/BooksAndCopies.png';
import CardBoardsImg from '../../assets/CardBoards.png';
import IronImg from '../../assets/IronImage.png';
import OverSizedIronImg from '../../assets/OverSizedIron.png';
// import SteelUtensilsImg from '../../assets/SteelUtensils.png';
// import AluminiumUtensilsImg from '../../assets/AluminiumUtensils.png';
// import CopperUtensilsImg from '../../assets/CopperUtensils.png';
import TinScrapImg from "../../assets/TinScrap.png";
import LightTinImg from "../../assets/LightTin.png";
import AirConditionar from "../../assets/AirConditionar.png";
import Refrigirator from "../../assets/Refrigirators.png";
import WashingMC from "../../assets/WashingMC.png";
import MotorCycle from '../../assets/MotorCycle.png'
import CarScrap from '../../assets/CarScrap.jpg'

const ScrapRates = () => {
  return (
    <div className='flex flex-col items-center min-h-screen w-full px-0 py-8 md:p-8'>
      <h2 className="text-2xl md:text-3xl font-bold uppercase text-gray-800">Our Scrap Rates</h2>

      {/* Important Note */}
      <div className="w-full m-8 flex flex-col bg-blue-100 p-4 rounded-lg shadow-md">
        <h3 className="text-lg my-4 md:text-xl font-bold uppercase text-gray-800 text-center">Important Note</h3>
        <ul className="list-disc list-inside border border-gray-300 rounded-lg p-4 bg-white">
          <li className="mb-2">
            Please note that prices can change based on the current scrap market conditions.
          </li>
          <li className="mb-2">
            For bulk pickups, prices may vary. Feel free to call us at <a href="tel:+917722840945" className="text-blue-600 hover:underline">+91-7722840945</a> for a quote.
          </li>
        </ul>
      </div>

      {/* Papers Section */}
      <div className="w-full mb-8">
        <h3 className="text-xl my-4 md:text-2xl font-bold uppercase text-gray-800 text-center">Papers</h3>
        <div className='flex justify-center items-center w-full flex-wrap gap-8'>
          <RateBox image={OfficePapersImg} rate={"₹10/Kg"} heading="Office Papers" desc="Clean and sorted office papers suitable for recycling." />
          <RateBox image={NewsPapersImg} rate={"₹10/Kg"} heading="Newspapers" desc="Recyclable newspapers and magazines in good condition." />
          <RateBox image={BooksAndCopiesImg} rate={"₹10/Kg"} heading="Books and Copies" desc="Old books and copies in usable or scrap condition." />
          <RateBox image={CardBoardsImg} rate={"₹11s/Kg"} heading="Cardboards" desc="Corrugated cardboard boxes and packaging material." />
        </div>
      </div>

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient" />

      {/* Plastic Section */}
      <div className="w-full mb-8">
        <h3 className="text-xl my-4 md:text-2xl font-bold uppercase text-gray-800 text-center">Plastic</h3>
        <div className='flex justify-center items-center w-full flex-wrap gap-8'>
          <RateBox image={SoftPlasticImg} rate={"₹12/Kg"} heading="Soft Plastic" desc="Flexible plastics such as bags, wrappers, and packaging material." />
          <RateBox image={PlasticItemsImg} rate={"₹8/Kg"} heading="Mixed Plastic" desc="Various plastic items like bottles, containers, and household items." />
        </div>
      </div>

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient" />

      {/* Metals Section */}
      <div className="w-full mb-8">
        <h3 className="text-xl my-4 md:text-2xl font-bold uppercase text-gray-800 text-center">Metals</h3>
        <div className='flex justify-center items-center w-full flex-wrap gap-8'>
          <RateBox image={IronImg} rate={"₹27/Kg"} heading="Iron Scrap" desc="Scrap iron materials including rods, pipes, and small iron items." />
          <RateBox image={OverSizedIronImg} rate={"₹26/Kg"} heading="Oversized Iron" desc="Large iron items such as coolers, iron beds, and bicycles." />
          {/* <RateBox image={SteelUtensilsImg} rate={"₹160/Kg"} heading="Steel Utensils" desc="Stainless steel kitchenware and utensils." /> */}
          {/* <RateBox image={AluminiumUtensilsImg} rate={"₹280/Kg"} heading="Aluminium Utensils" desc="Aluminium pots, pans, and kitchen utensils." /> */}
          {/* <RateBox image={CopperUtensilsImg} rate={"₹480/Kg"} heading="Copper Utensils" desc="Copper kitchenware, utensils, and other scrap copper items." /> */}
          <RateBox image={TinScrapImg} rate={"₹21/Kg"} heading="Heavy Tin Scrap" desc="Large tin items like cupboards, oil barrels, and coolers." />
          <RateBox image={LightTinImg} rate={"₹19/Kg"} heading="Light Tin" desc="Small tin items such as pails, cans, and lightweight tin containers." />
        </div>
      </div>

      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient" />

      {/* E- Waste */}
      <div className="w-full mb-8">
        <h3 className="text-xl my-4 md:text-2xl font-bold uppercase text-gray-800 text-center">E - Waste</h3>
        <div className='flex justify-center items-center w-full flex-wrap gap-8'>
          <RateBox image={AirConditionar} heading="Air Conditioner" rate={"Call : 7722840945"} desc="Call us at our number " />
          <RateBox image={Refrigirator} heading="Refrigerator" rate={"Call : 7722840945"} desc="Call us at our number " />
          <RateBox image={WashingMC} heading="Washing Machine" rate={"Call : 7722840945"} desc="Call us at our number " />
        </div>
      </div>


      <hr className="w-full border h-[4px] dark:border-blue-900 bg-custom-gradient" />

      {/* Others */}
      <div className="w-full mb-8">
        <h3 className="text-xl my-4 md:text-2xl font-bold uppercase text-gray-800 text-center">Others</h3>
        <div className='flex justify-center items-center w-full flex-wrap gap-8'>
          <RateBox image={CarScrap} heading="Car Scrap" rate={"Call : 7722840945"} desc="Call us at our number " />
          <RateBox image={MotorCycle} heading="MotorCycle Bike Scrap" rate={"Call : 7722840945"} desc="Call us at our number " />
        </div>
      </div>

    </div>
  );
}

export default ScrapRates;
