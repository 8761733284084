import { motion } from 'framer-motion';
import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { IoMdCall } from 'react-icons/io';
import { MdOutlineSupportAgent } from 'react-icons/md';

const Contact = () => {
  // Define animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };

  return (
    <motion.div
      className="min-h-screen flex flex-col items-center justify-center p-6 bg-gray-100 w-full"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="bg-white shadow-lg rounded-lg p-8 w-full sm:max-w-2xl transition-transform transform hover:scale-105 duration-300"
        variants={itemVariants}
      >
        <h1 className="text-center text-3xl font-bold text-gray-800 mb-6">Contact Us</h1>

        <div className="flex gap-2 text-gray-700 flex-col">
          <div className="flex w-full gap-2 flex-col md:flex-row">
            {/* Phone Section */}
            <motion.div
              className="flex items-center bg-blue-50 p-4 rounded-lg hover:shadow-md md:w-1/2 transition-shadow duration-200"
              variants={itemVariants}
            >
              <IoMdCall className="text-blue-500 mr-4 scale-150" />
              <div>
                <strong className="block font-semibold">Phone</strong>
                <p>
                  <a href="tel:+917722840945" className=" hover:underline">+91 7722840945</a>,
                </p>
                <p>
                  <a href="tel:+919424481177" className="hover:underline">+91 9424481177</a>
                </p>
              </div>
            </motion.div>

            {/* Email Section */}
            <motion.div
              className="flex items-center bg-blue-50 p-4 rounded-lg hover:shadow-md md:w-1/2 transition-shadow duration-200"
              variants={itemVariants}
            >
              <FaEnvelope className="text-blue-500 mr-4 scale-[4] md:scale-150 " />
              <div>
                <strong className="block font-semibold">Email</strong>
                <p>
                  <a href="mailto:contact@thescrapwala.com" className=" hover:underline">
                    contact@thescrapwala.com,
                  </a>
                </p>
                <p>
                  <a href="mailto:support@thescrapwala.com" className="hover:underline">
                    support@thescrapwala.com
                  </a>
                </p>
              </div>
            </motion.div>
          </div>

          <div className="flex w-full gap-2 flex-col md:flex-row">
            {/* Customer Support Section */}
            <motion.div
              className="flex items-center bg-blue-50 p-4 rounded-lg hover:shadow-md md:w-1/2 transition-shadow duration-200"
              variants={itemVariants}
            >
              <MdOutlineSupportAgent className="text-blue-500 mr-4 scale-[2]" />
              <div className="flex flex-col">
                <strong className="font-semibold">Customer Support</strong>
                <p>Available from 10 AM - 7 PM</p>
                <p>Available from Monday - Sunday</p>
              </div>
            </motion.div>

            {/* Location Section */}
            <motion.div
              className="flex items-center bg-blue-50 p-4 rounded-lg hover:shadow-md md:w-1/2 transition-shadow duration-200"
              variants={itemVariants}
            >
              <FaMapMarkerAlt className="text-blue-500 mr-4 scale-[2]" />
              <div className="flex flex-col">
                <strong className="font-semibold">Find Us Here</strong>
                <p>KGN Enterprises, Arya Ward Company Garden, Tikari Road, Betul (MP)</p>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Additional Information */}
        <div className="mt-6 text-gray-700 text-justify">
          <p className="text-lg text-gray-700 text-justify bg-white p-6 rounded-xl shadow-lg">
            TheScrapWala is an online kabadiwala service in Betul. You can request a pickup via call or WhatsApp, and our team will come to your doorstep without any extra charge to offer the best price for your scrap.
            <br />
            <b> We buy recyclable scrap such as old iron scrap, old plastic scrap, old paper scrap, old books scrap, old copies scrap, and old e-waste scrap.</b>
          </p>

        </div>
      </motion.div>
    </motion.div>
  );
};

export default Contact;
