import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, Navbar, Footer, About, Contact } from './Components';
import { UserProvider } from './store/userContext';
import { ToastContainer } from 'react-toastify';
import FourOFour from './Components/FourOFour/FourOFour';
import './App.css'
import './index.css'
import ScrapRates from './Components/Scrap Rates/ScrapRates';
import Button from './Components/Button/Button';

export default function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        {/* Include Navbar and Sidebar as they are usually present on all pages */}
        <Navbar />
        <Button />
        {/* <Sidebar /> */}

        {/* Main Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/scrap-rates" element={<ScrapRates />} />
          <Route exact path="*" element={<FourOFour />} />
        </Routes>

        {/* Include Footer, it usually appears on all pages */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Footer />
      </BrowserRouter>
    </UserProvider>
  );
}
