import React from 'react';
import { motion } from 'framer-motion';
import TeamImage from '../../assets/Logo.png';
import { Link } from 'react-router-dom';

export function About() {
  return (
    <motion.div
      className="flex flex-col lg:flex-row items-center justify-between w-full p-2 md:p-6 relative"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >

      {/* Left Section: Heading and Description */}
      <motion.div
        className="w-full lg:w-1/2 p-6 md:p-8 space-y-6 bg-white rounded-xl shadow-lg"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.7 }}
      >
        {/* Main Heading */}
        <h1 className="lg:text-5xl text-2xl font-bold text-green-900 lg:text-left text-center">
          About TheScrapWala
        </h1>

        {/* Subheading */}
        <p className="text-lg text-gray-600 text-justify lg:text-left">
          At TheScrapWala, we revolutionize recycling with efficient, transparent, and eco-friendly scrap management solutions. Our platform connects households, businesses, and recyclers for effortless and rewarding scrap disposal.
        </p>

        {/* Mission and Values */}
        <div className="space-y-4">
          {/* Mission */}
          <div className="p-4 bg-green-100 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-green-800">Our Mission</h2>
            <p className="text-gray-700 text-justify">
              We create a sustainable future by making recycling accessible and profitable for everyone. We reduce waste and promote a cleaner environment through convenient scrap collection and recycling services.
            </p>
          </div>

          {/* Values */}
          <div className="p-4 bg-blue-100 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-blue-800">Our Values</h2>
            <ul className="list-disc list-inside text-gray-700 space-y-2">
              <li>Transparency: Building trust through honest communication.</li>
              <li>Customer-Centric: Prioritizing customer satisfaction.</li>
              <li>Sustainability: Protecting the environment responsibly.</li>
              <li>Innovation: Continuously improving recycling services.</li>
            </ul>
          </div>
        </div>
      </motion.div>

      <motion.div
        className="w-full lg:w-1/2 p-2 md:p-8 flex flex-col items-center"
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.7 }}
      >
        <img
          src={TeamImage}
          alt="Our Logo"
          className="rounded-xl shadow-lg w-full lg:w-1/2 object-cover mb-6"
        />

        {/* Additional Information */}
        <p className="text-lg text-gray-700 text-justify bg-white p-6 rounded-xl shadow-lg">
          TheScrapWala is an online kabadiwala service in Betul. You can request a pickup via call or WhatsApp, and our team will come to your doorstep without any extra charge to offer the best price for your scrap.
          <br />
          <b> We buy recyclable scrap such as old iron scrap, old plastic scrap, old paper scrap, old books scrap, old copies scrap, and old e-waste scrap.</b>
        </p>


        {/* Call to Action */}
        <div className="mt-4 space-y-2 text-center p-4 gap-2 flex flex-col md:flex-row items-center justify-center">
          <p className="text-base text-gray-600">Join us on this journey towards a cleaner and greener world. Let's turn waste into value together.</p>
          <Link
            to="/contact"
            className="text-white bg-green-700 hover:bg-green-800 rounded-full px-8 text-lg transition duration-200 ease-in-out shadow-md"
          >
            Get in Touch
          </Link>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default About;
