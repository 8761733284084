import React from 'react';

const RateBox = ({ image, rate, heading, desc = "" }) => {
  return (
    <div className='flex items-center justify-center sm:w-full w-1/2 flex-wrap md:w-3/12 rounded-lg p-0 h-64 shadow-xl hover:shadow-2xl hover:scale-105 transition-transform duration-200 ease-in-out bg-white flex-col border-2 '>
      {/* Image */}
      <img src={image} className='w-32 md:w-40 mb-2' alt={heading} />

      {/* Rate */}
      <p className="text-lg font-semibold text-green-600">{rate}</p>

      {/* Heading */}
      <p className="text-base md:text-lg font-bold text-gray-800 my-1 ">{heading}</p>
      {/* Description */}
      {desc && <p className="text-xs md:text-sm text-gray-500 text-center px-2">{desc}</p>}
    </div>
  );
}

export default RateBox;
