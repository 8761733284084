import React from 'react';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';

const FourOFour = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <div className="text-9xl font-bold text-gray-800">404</div>
        <div className="text-3xl font-semibold text-gray-600 mb-4">Oops! Page not found</div>
        <div className="text-lg text-gray-500 mb-8">
          The page you are looking for might be in another castle.
        </div>
        <Link
          to="/"
          className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300"
        >
          Go Home
        </Link>
        <div className="mt-8 text-gray-600 flex flex-col items-center justify-center">
          <FaExclamationTriangle className="inline-block text-4xl mr-2" />
          Something went wrong. Please try again later.
        </div>
      </div>
    </div>
  );
}

export default FourOFour;
