import React, { useState } from 'react';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../../assets/Logo.png'

const Navbar = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  const navItems = ['home', 'about', 'contact', 'scrap-rates'];


  return (
    <div className="w-full bg-custom-gradient z-40">
      <nav className="max-w-7xl m-auto flex justify-between items-center p-8 md:p-2 relative">
        <Link to="/" className="text-gray-50 text-3xl font-bold flex items-center space-x-1 lg:space-x-5">
          <img src={Logo} className='w-16 rounded-xl lg:scale-[1.5]'
            style={{ filter: "invert(1) hue-rotate(180deg)" }}
          />
          <p className='text-xl md:text-2xl'>TheScrapWala</p>
        </Link>

        {/* Desktop Links */}
        <ul className="hidden lg:flex space-x-6 items-center">
          {navItems.map((item) => (
            <li key={item}>
              <Link
                to={`/${item}`}
                className={`transition-all duration-300 uppercase ${location.pathname === `/${item}`
                  ? 'bg-white text-green-900'
                  : 'text-white'
                  } hover:text-green-900 hover:bg-white rounded-md px-3 py-2 text-sm font-bold`}
              >
                {item}
              </Link>
            </li>
          ))}

        </ul>

        {/* Mobile Menu Icon */}
        <div className="lg:hidden text-gray-50 relative">
          {!toggle ? (
            <HiMenuAlt4
              className='z-10 cursor-pointer relative transition-opacity duration-500 ease-in-out top-[0px] left-[20%] h-6 w-6 shadow-[0px_0px_14px_-2px_#f4a3d3] rounded-3xl'
              onClick={() => setToggle(true)}
            />
          ) : (
            <HiX
              className='z-20 cursor-pointer relative transition-opacity duration-500 ease-in-out top-[0px] left-[20%] h-6 w-6 shadow-[0px_0px_14px_-2px_#f4a3d3] rounded-3xl'
              onClick={() => setToggle(false)}
            />
          )}
        </div>

        {/* Mobile Menu */}
        {toggle && (
          <motion.div
            initial={{ x: 100 }}
            animate={{ x: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-0 z-10 right-0 bg-custom-gradient w-full h-screen lg:hidden flex flex-col items-center justify-center overflow-hidden"
          >
            <ul className="space-y-6 text-center flex flex-col items-center justify-center">
              {navItems.map((item) => (
                <li key={item}>
                  <Link
                    to={`/${item}`}
                    className={`transition-all duration-300 uppercase ${location.pathname === `/${item}`
                      ? 'bg-white text-green-900'
                      : 'text-white'
                      } hover:text-green-900 hover:bg-white rounded-md px-3 py-2 text-sm font-bold`}
                    onClick={() => {
                      setToggle(false);
                    }}
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
