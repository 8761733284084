import React from 'react';
import { FaPhone, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const Button = () => {
  return (
    <div className="fixed bottom-4 right-4 flex flex-col space-y-3 z-50">
      {/* Phone Call Button */}
      <a href="tel:+917722840945" className="flex items-center justify-center w-12 h-12 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-200">
        <FaPhone className="text-xl" />
      </a>

      {/* Email Button */}
      <a href="mailto:contact@thescrapwala.com" className="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-200">
        <FaEnvelope className="text-xl" />
      </a>

      {/* WhatsApp Button */}
      <a href="https://wa.me/919424481177" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-12 h-12 bg-green-500 text-white rounded-full hover:bg-green-600 transition duration-200">
        <FaWhatsapp className="text-xl" />
      </a>
    </div>
  );
};

export default Button;
