import React from 'react';
import { Link } from 'react-router-dom';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import Logo from '../../assets/Logo.png';

const Footer = () => {
  return (
    <footer className="bg-white shadow bg-custom-gradient flex flex-col justify-center md:justify-between ">
      <div className="w-full max-w-screen-xl mx-auto md:p-8 flex flex-col items-center justify-center">
        {/* Top Section */}
        <div className="w-full flex flex-col sm:flex-row sm:items-center sm:justify-between mb-4 sm:mb-6 text-center sm:text-left">
          <div className="mb-4 sm:mb-0">
            <Link to="/" className="text-gray-50 text-3xl font-bold flex items-center space-x-1 justify-center">
              <img src={Logo} className='w-16 rounded-xl scale-[2]'
                style={{ filter: "invert(1) hue-rotate(180deg)" }}
              />
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6 text-white">
            <Link to="/" className="hover:underline">Home</Link>
            <Link to="/about" className="hover:underline">About Us</Link>
            <Link to="/contact" className="hover:underline">Contact</Link>
          </div>
        </div>

        <hr className="w-full border-gray-300 my-4" />

        {/* Contact Us Section */}
        <div className="w-full flex flex-col sm:flex-row justify-between text-white space-y-6 sm:space-y-0">
          {/* Contact Info */}
          <div className="flex flex-col text-center sm:text-left space-y-2">
            <h3 className="text-xl font-bold">Contact Us</h3>
            <div className="flex items-center justify-center sm:justify-start space-x-2">
              <FaPhone className="text-white" />
              <div className="flex md:flex-row flex-col gap-2">
                <p>
                  <a href="tel:+917722840945" className="text-white hover:underline">+91 7722840945</a>,
                </p>
                <p>
                  <a href="tel:+919424481177" className="text-white hover:underline">+91 9424481177</a>
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center sm:justify-start space-x-2">
              <FaEnvelope className="text-white" />
              <div className='flex md:flex-row flex-col gap-2'>
                <p>
                  <a href="mailto:contact@thescrapwala.com" className="text-white hover:underline">
                    contact@thescrapwala.com,
                  </a>
                </p>
                <p>
                  <a href="mailto:support@thescrapwala.com" className="text-white hover:underline">
                    support@thescrapwala.com
                  </a>
                </p>
              </div>
            </div>
            <p className="mt-2">
              <span className="font-semibold">Regd. Address:</span> KGN Enterprises, Arya Ward Company Garden, Tikari Road, Betul (MP)
            </p>
          </div>

          {/* Attribution Section */}
          <div className="flex flex-col text-center sm:text-left space-y-2">
            <h3 className="text-xl font-bold">Attribution</h3>
            <p>
              Images used on this website are sourced from <a href="https://www.freepik.com/" target="_blank" rel="noopener noreferrer" className="hover:underline">Freepik</a>. <br />
              Designed by <a href="https://www.freepik.com/" target="_blank" rel="noopener noreferrer" className="hover:underline">Freepik</a>.
            </p>
          </div>


          {/* Quick Links Section */}
          <div className="flex flex-col text-center sm:text-left space-y-2">
            <h3 className="text-xl font-bold">Quick Links</h3>
            <Link to="/scrap-rates" className="hover:underline">Scrap Rates</Link>
          </div>
        </div>
      </div>

      {/* <div className="w-full max-w-screen-xl mx-auto ">
        <h3 className="text-xl font-bold text-center text-white my-4">Find Us Here</h3>
        <iframe
          title="Google Map Location"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3701.640637636465!2d77.89403267528131!3d21.90990987996924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDU0JzM1LjciTiA3N8KwNTMnNDcuOCJF!5e0!3m2!1sen!2sin!4v1728220442475!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        />

      </div> */}

      {/* Bottom Section */}
      <div className="bg-black text-white w-full py-2 text-center">
        <p>Crafted with care and innovation. Built with <span className="text-red-500 font-bold">❤</span> by <Link target='_blank' to="https://linkedin.com/in/mk-moin-khan" className="hover:underline text-white font-bold">Moin Khan</Link></p>
        <p>Contact: <a href="mailto:moink3181@gmail.com" className="hover:underline text-white">moink3181@gmail.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;
