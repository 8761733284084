import React from 'react';
import { motion } from 'framer-motion';
import ShedulePickUp from '../../assets/SheduleAPickUp.png';
import PickUpAtYourAddress from '../../assets/PickUpAtYourAddress.jpg';
import GetMoney from '../../assets/GetMoney.png';
import '../../App.css';

const HowItWorks = () => {
  return (
    <div className='flex flex-col w-full p-4'>
      <motion.div
        className="flex flex-col items-center text-justify justify-between w-full bg-gray-100 pb-6 relative"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold p-2 uppercase text-gray-800">How It Works</h2>
      </motion.div>

      <motion.div className='flex justify-center lg:justify-around gap-8 md:gap-2 flex-wrap'>
        <div className="group relative w-full md:w-1/2 lg:w-1/4 p-[4px] h-64 flex items-center justify-center rounded-xl  overflow-hidden">
          <div className="absolute w-[200%] h-[200%] rounded-lg overflow-hidden border-2 border-transparent group-hover:animate-spin_right bg-gradient-to-r from-[#007A8E] via-[#4fc681] to-[#f9f9f9]"></div>

          <div className="relative flex flex-col items-center justify-center w-full h-full bg-white rounded-lg p-2">
            <img style={{ filter: 'hue-rotate(1381deg)' }} src={ShedulePickUp} className='w-40 md:w-48 object-cover' alt="Schedule Pickup" />
            <p className=' text-xl text-center font-bold'>Schedule a Pickup.</p>
          </div>
        </div>

        <div className="group relative w-full md:w-1/2 lg:w-1/4 p-[4px] h-64 flex items-center justify-center rounded-xl  overflow-hidden">
          <div className="absolute w-[200%] h-[200%] rounded-lg overflow-hidden border-2 border-transparent group-hover:animate-spin_right bg-gradient-to-r from-[#007A8E] via-[#4fc681] to-[#f9f9f9]"></div>

          <div className="relative flex flex-col items-center justify-center w-full h-full bg-white rounded-lg p-2">
            <img src={PickUpAtYourAddress} className='w-40 md:w-48 object-cover' alt="Schedule Pickup" />
            <p className=' text-xl text-center font-bold'>Pickup At Your Address.</p>
          </div>
        </div>

        <div className="group relative w-full md:w-1/2 lg:w-1/4 p-[4px] h-64 flex items-center justify-center rounded-xl  overflow-hidden">
          <div className="absolute w-[200%] h-[200%] rounded-lg overflow-hidden border-2 border-transparent group-hover:animate-spin_right bg-gradient-to-r from-[#007A8E] via-[#4fc681] to-[#f9f9f9]"></div>

          <div className="relative flex flex-col items-center justify-center w-full h-full bg-white rounded-lg p-2">
            <img style={{ filter: 'hue-rotate(1381deg)' }} src={GetMoney} className='w-40 md:w-48 object-cover' alt="Schedule Pickup" />
            <p className=' text-xl text-center font-bold'>Receive Your Payment.</p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HowItWorks;
